.App {
  text-align: center;
  background-color: orange;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}


.App-main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  padding: 20px;
}

.quadrant {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.studio-logo {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.project-logo {
  width: 100%; /* Set the width of the images to 100% of their container */
  height: auto; /* Set the height to auto to maintain aspect ratio */
  object-fit: contain; /* Keep the aspect ratio of the images */
}

.App-footer {
  padding: 20px;
}

.twitter-logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-top: 20px;
}
